.header-container{
  width: 100%;
  display: flex;
  background-color: $containerBackground;
  justify-content: center;

  .logo{
    @include float-left();
    clear:none;
    width:150px;
    height:60px;
    padding:12px;
    margin:0px;

    object-fit:contain;
    object-position: center;

    &.mobile{
      display: none;
      width:150px;
      height: 35px;
    }

    @media only screen and (max-width:$mobileWidth){
      &.desktop{
        display:none;
      }
      &.mobile{
        display:block;
      }
    }
  }
}