body, #root, #app-container,.App{
  width: 100%;
  height: 100%;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
	display: flex;
  flex-direction: column;
	margin:0;
	background: $grey;
	color: $primaryBlack;

	@media only print{
		background:transparent;
	}
}

.main-container{
	display: flex;
	flex-direction: column;
  width: 100%;
  align-items: center;
}

.content-wrapper{
  display: flex;
	width: 100%;  
	// margin: 0 auto;

  @media only screen and (min-width: 1400px){
		width:1400px;
	}
}