.footer-container{
  // position:absolute;
  left:0;
  bottom:0;
  box-sizing: border-box;
  .footer-content{
    padding: 20px;
    font-size: 14px;
    text-align: center;
    color: $darkGrey;

    .contact-us{
      color: $darkGrey;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}