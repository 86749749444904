.assessment-container{
  background-color: $containerBackground;
  width: 100%;
  height: 100%;

  .assessment-content{
    width: 100%;
    height: 100%;
    .button-container{
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0px 20px 20px 20px;

      .divider{
        width: 10px;
      }

      &.choose-many{
        margin-left: 0px;
        padding: 10px 0px 10px 0px;
        
        &.submit{
          justify-content: flex-end;
        }
      }
    }
  }
}