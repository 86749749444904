$fontFamilyDefault: 'Noto Sans';
$fontFamilyDefaultWeight: 400;
$boldFontWeight: 700;


html, body, button, input, select, textarea, .pure-g, .pure-g [class *= "pure-u"] {
	font-family: $fontFamilyDefault;
	font-weight: $fontFamilyDefaultWeight;
}
p{
  font-family: $fontFamilyDefault;
  font-weight: $fontFamilyDefaultWeight;
}

@mixin heading(){
  font-family: $fontFamilyDefault;
  font-weight: $fontFamilyDefaultWeight;
  letter-spacing: .20em;
}

h6{
	font-size:.8rem;
}

.heading{
  @include heading();
}