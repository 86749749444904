@mixin full-width() {
	position:relative;
	float:left;
	clear:both;
	width:100%;
}
@mixin float-left() {
	position:relative;
	float:left;
	clear:none;
}
@mixin float-right() {
	position:relative;
	float:right;
	clear:none;
}