.progress-bar-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $lightGrey;
  box-sizing: border-box;
  padding: 20px;
  .progress-bar{
    display: flex;
    width: 100%;
    height: 8px;
    background-color: #CCCCCC;
    border-radius: 4px;

    .progress{
      background-color: $primaryGreen;
      border-radius: 4px;
      transition: width 0.5s ease-in-out;
    }
  }
}