.assessment-question-container{
  display: flex;
  flex-direction: column;
  padding: 20px;

  .question-container{
    display: flex;
    font-size: 18px;
    margin-bottom: 20px;

    .question-number{
      margin-right: 5px;
    }

    .question-text{
      color: $primaryBlack;
    }

    &.animation{
      -webkit-transition: all ease $animationDuration;
      -moz-transition: all ease $animationDuration;
      -o-transition: all ease $animationDuration;
      -ms-transition: all ease $animationDuration;
      opacity: 0.2;
      transition: opacity $animationDuration ease;
    }

  }

  .question-options-container{
    // width: 100%;
    display: flex;
    // flex-direction: column;

    @media (max-width: 400px){
      .check-mark{
        // &.rating-10{
        //   display: none;
        // }
        position: absolute;
        width: 19px;
        height: 19px;
        left: 2%;
      }
    }

    @media (min-width:400px){
      .check-mark{
        position: absolute;
        width: 19px;
        height: 19px;
        left: 2%;
      }
    }

    &.rating-10{
      width: 100%;
      // justify-content: center

      @media(max-width: 800px){
        flex-wrap: wrap;
      }
    }

    &.choices{
      justify-content: center;

      @media (max-width:800px){
        align-items: center;
        flex-direction: column;
      }
    }

    &.choose-many{
      flex-direction: column;
    
      .question-option{
        display: flex;
      }
      .option-ranking {
        color: $primaryBlue;
        padding-right: 10px;
      }
    }

  }

  .rating-helper-info{
    width: 100%;
    font-size: 14px;
    color: $primaryBlack;
    margin-top: 0px;
    margin-bottom: 10px;
    color: $darkGrey;

    &.rating-10{
      display: flex;
      justify-content: space-between;
    }

    &.rating-5{
      @media (min-width: 350px){   
        display: flex;
        justify-content: space-between;
      }
    }
  }
}