.statement-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // height: 30vh;

  .statement-text{
    font-size: 18px;
    font-weight: 500;
    color: $primaryBlack;
    margin-bottom: 20px;
  }

  .statement-button-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .statement-divider{
      width: 10px;
    }
  }
}