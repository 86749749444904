.loading-container{
  width: 100%;
  height: 100vh;
  padding: 20px;
  font-size: 20px;

  .loading-content{
    display: flex;
    justify-content: center;
    align-items: end;
    .loading-text{
      margin-left: 10px;
    }
  }
}