.error-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 80px;
  font-size: 20px;
  text-align: center;
  background-color: #FFFFFF;

  @media only screen and (max-width:$mobileWidth){
    border-top: 2px solid $grey;
    font-size: 15px;
  }
}