$primaryBlack: #000000;
$primaryGreen: #068939;
$primaryYellow: #DFE343;
$primaryBlue: #017EB3;

$secondaryOrange: #FFAE00;
$secondaryGreen: #3EEB69;
$secondaryBlue: #43DFE3;
$secondaryRed: #FF4F00;

$lightGrey: #F2F2F2;
$grey: #E9E9E9;
$darkGrey: #6B6B6B;

$containerBackground: #FFFFFF;
$white: #FFFFFF;

$mobileWidth: 600px;

$animationDuration: 1s;