.status-container{
  background-color: $containerBackground;
  width: 100%;
  height: 100%;

  .status-title{
    font-size: 1.3em;
    font-weight: bold;
    margin: 20px 0px 20px 20px;
    text-align: center;
  }

  .status-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px 20px 20px;

    .table-cell {
      text-align: center;
    }
    table {
      width: 100%;
      // border: 1px solid lightgray;
    }
    
    tbody {
      border-bottom: 1px solid lightgray;

    }
    
    th {
      border-bottom: 1px solid lightgray;
      // border-right: 1px solid lightgray;
      padding: 2px 4px;
    }
  }
}