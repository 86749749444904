@mixin button(){
  padding: 12px 26px;
  border-radius:3px;
  font-family: $fontFamilyDefault;
  font-weight:700;
  font-size:16px;//default font size
  //letter-spacing:.15em;

  box-sizing:border-box;

  border:2px solid $primaryGreen;
  cursor:pointer;

  //text-transform: uppercase;

  // @include default-animation();

  //default = primary
  background-color:$primaryGreen;
  color:$white;

  &.move-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px 3px 10px;
  }

  &:hover:not(.disabled):not(:disabled){
    background-color:lighten($primaryGreen, 5%);
    border-color:lighten($primaryGreen, 5%);
  }
  &:focus{
    outline:none !important;
  }

  &.disabled,&:disabled{
    opacity:.5;
    cursor:default;
  }
}


.button{
  @include button();

  &.button-sm{
    padding:10px 25px;
    font-size:14px;
  }
  &.button-tiny{
    padding:5px 12px;
    font-size:14px;
  }
  &.button-micro{
    padding:3px 8px;
    font-size:13px;
  }
}

@mixin primary-outline(){
  background-color:transparent;

  &.sm{
    padding:5px 19px;
  }

  border:2px solid $primaryGreen;
  color:$primaryGreen;

  &:hover:not(.disabled){
    background-color:$primaryGreen;
    border-color:$primaryGreen;
    color:$white;
  }
}

.primary-outline{
  @include primary-outline();
}

@mixin secondary-outline(){
  background-color:transparent;

  &.sm{
    padding:5px 19px;
  }

  border:2px solid $primaryBlue;
  color:$primaryBlue;

  &:hover:not(.disabled):not(:disabled),&.hover{
    background-color:$primaryBlue;
    border-color:$primaryBlue;
    color:$white;
  }
}

.secondary-outline{
  @include secondary-outline();
}

@mixin question-button{
  background-color:transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin-bottom: 10px;
  font-size:16px;
  border:2px solid $primaryBlack;
  color:$primaryBlack;
  opacity: .7;
  position: relative;


  &.rating-10{
    &:last-child{
      margin-right: 0;
    }

    @media(max-width: 800px){
      margin-right: 2%;
      max-width: 18.4%;
      &:nth-child(5){
        margin-right: 0px;
      }
      // &:last-child{
      //   margin-right: 0px;
      // }
    }
    @media(min-width: 800px){
      margin-right: 8px;
    }
  }

  &.rating-5{
    // flex: 0 1 auto;
    margin-right: 5px;

    &:last-child{
      margin-right: 0;
    }
  }

  &.choice{
    @media(min-width: 800px){
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  &.selected {
    border:2px solid $primaryBlue;
    background-color: $primaryBlue;
    color: $white;
    opacity: 1;
    &.animation {
      -webkit-transition: all ease $animationDuration;
      -moz-transition: all ease $animationDuration;
      -o-transition: all ease $animationDuration;
      -ms-transition: all ease $animationDuration;
      transition: all ease $animationDuration;
      box-shadow: inset 400px 0  0 0 $primaryBlue;
      color: $white;
      border: 2px solid $primaryBlue;
    }
  }

  &.choose-many{
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 10px;

    &.best-three-options{
      max-width: 100%;
      font-size: 14px;
      &.selected {
        border:2px solid $primaryBlue;
        background-color: $primaryBlue;
        color: $white;
        opacity: 1;
      }

      // &:hover:not(.disabled):not(:disabled){
      //   border:2px solid $primaryBlue;
      //   background-color: $primaryBlue;
      //   color: $white;
      //   opacity: .5;
      //   @media(max-width: $mobileWidth){
      //     border: 2px solid $primaryBlack;
      //     background-color: transparent;
      //     color: $primaryBlack;
      //     opacity: .7;
      //     &.selected{
      //       border:2px solid $primaryBlue;
      //       background-color: $primaryBlue;
      //       color: $white;
      //       opacity: 1;
      //     }
      //   }
      // }
    }

    &.least-option{
      max-width: 100%;
      font-size: 14px;
      &.selected {
        border:2px solid $secondaryRed;
        background-color: $secondaryRed;
        color: $white;
        opacity: 1;
      }
      
      &:hover:not(.disabled):not(:disabled){
        border:2px solid $secondaryRed;
        background-color: $secondaryRed;
        color: $white;
        opacity: .5;
        @media(max-width: $mobileWidth){
          border: 2px solid $primaryBlack;
          background-color: transparent;
          color: $primaryBlack;
          opacity: .7;
          &.selected{
            border:2px solid $secondaryRed;
            background-color: $secondaryRed;
            color: $white;
            opacity: 1;
          }
        }
      }
    }
    
  }

  &:hover:not(.disabled):not(:disabled),&.hover{
    border:2px solid $primaryBlue;
    background-color: $primaryBlue;
    color: $white;
    opacity: .5;
    &.selected{ 
      border:2px solid $primaryBlue;
      background-color: $primaryBlue;
      color: $white;
      opacity: 1;

      &.animation {
        -webkit-transition: all ease $animationDuration;
        -moz-transition: all ease $animationDuration;
        -o-transition: all ease $animationDuration;
        -ms-transition: all ease $animationDuration;
        transition: all ease $animationDuration;
        box-shadow: inset 400px 0  0 0 $primaryBlue;
        color: $white;
        border: 2px solid $primaryBlue;
      }
    }
  
    // ignore hover effect on mobile devices unless selected
    // @media(max-width: $mobileWidth){
    @media only screen and (hover: none) and (pointer: coarse){
      border: 2px solid $primaryBlack;
      background-color: transparent;
      color: $primaryBlack;
      opacity: .7;
      &.selected{ 
        border:2px solid $primaryBlue;
        background-color: $primaryBlue;
        color: $white;
        opacity: 1;
  
        &.animation {
          -webkit-transition: all ease $animationDuration;
          -moz-transition: all ease $animationDuration;
          -o-transition: all ease $animationDuration;
          -ms-transition: all ease $animationDuration;
          transition: all ease $animationDuration;
          box-shadow: inset 400px 0  0 0 $primaryBlue;
          color: $white;
          border: 2px solid $primaryBlue;
        }
      }
    }
    
  }
}

.question-button{
  @include question-button();
}

@mixin grey-outline(){
  background-color:transparent;

  padding-left: 20px;
  padding-right: 20px;
  &.sm{
    padding:5px 19px;
  }

  border:2px solid $darkGrey;
  color:$darkGrey;

  &:hover:not(.disabled):not(:disabled),&.hover{
    background-color:$darkGrey;
    border-color:$darkGrey;
    color:$white;

  }
}

.grey-outline{
  @include grey-outline();
}
